@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

::-webkit-scrollbar {
  width: 5px;
  height: 3px;
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:hover {
  background-color: rgb(0, 83, 238, 1);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #aaa;
  border-radius: 10px;
}
